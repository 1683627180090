import React, { useEffect } from "react"
import PropTypes from "prop-types"
import { navigate } from "gatsby"
import { isLoggedIn } from "../services/auth"
const PrivateRoute = ({ component: Component, location, ...rest }) => {
  const canView = isLoggedIn() || location.pathname === `/app/login`

  useEffect(() => {
    if (!canView) navigate("/app/login")
  }, [canView])

  if (!canView) {
    return null
  }
  return <Component {...rest} />
}
PrivateRoute.propTypes = {
  component: PropTypes.func.isRequired,
  location: PropTypes.object.isRequired,
}
export default PrivateRoute

import React, { useEffect } from "react"
import { navigate } from "gatsby"
import { logout } from "../services/auth"
const Logout = () => {
  useEffect(() => {
    logout(() => navigate(`/app/login`))
  }, [])
  return null
}
export default Logout

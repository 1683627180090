import React, { useState, useEffect, useCallback, useRef } from "react"
import PropTypes from "prop-types"
import { navigate } from "gatsby"
import { handleLogin, isLoggedIn } from "../services/auth"

const APPLE_CLIENT_ID = "me.alakkad.Balance"
const APPLE_SCOPE = "email,fullName"
const useAppleAuthServices = (location, onReceive, { state = "" } = {}) => {
  const [error, setError] = useState(null)
  const SCRIPT_ID = "apple-authentication-services-script"
  const element = useRef(null)

  const onLoad = useCallback(() => {
    if (!window.AppleID || !window.AppleID.auth) return
    setError(null)
    try {
      window.AppleID.auth.init({
        clientId: APPLE_CLIENT_ID,
        scope: APPLE_SCOPE,
        state,
        redirectURI: location.href,
      })
    } catch (error) {
      setError(error)
    }
  }, [location, state])

  useEffect(() => {
    element.current = document.getElementById(SCRIPT_ID)
    if (element.current) {
      if (window.AppleID) onLoad()
      return
    }

    element.current = document.createElement("script")
    element.current.id = SCRIPT_ID
    element.current.async = true
    element.current.src =
      "https://appleid.cdn-apple.com/appleauth/static/jsapi/appleid/1/en_US/appleid.auth.js"
    element.current.onload = onLoad
    element.current.onunload = setError
    document.head.appendChild(element.current)
    return () => {
      element.current.onload = null
      element.current.onunload = null
      element.current.parentElement.removeChild(element.current)
    }
  }, [onLoad])

  const tree = (
    <div
      id="appleid-signin"
      className="signin-button"
      data-color="black"
      data-border="true"
      data-type="sign in"
    ></div>
  )

  // FIXME(LA): Post body
  // const { code, id_token: idToken, state: receivedState, user } = location.query
  // useEffect(() => {
  //   if (code && id_token && user) {
  //     onReceive({ code, idToken, state: receivedState, user })
  //   }
  // }, []) // eslint-disable-line react-hooks/exhaustive-deps

  return [tree, error]
}

const Login = ({ location }) => {
  useEffect(() => {
    if (isLoggedIn()) {
      navigate(`/app/`)
    }
  }, [])

  const onReceiveCredentials = useCallback(() => {
    //
  }, [])

  const [tree, error] = useAppleAuthServices(location, onReceiveCredentials)
  return (
    <>
      <h1>Log in</h1>
      <p>Click on the button below to log in with apple</p>
      {tree}

      {error ? <p>Error: {error.message}</p> : null}
    </>
  )
}
Login.propTypes = { location: PropTypes.object.isRequired }
export default Login
